import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import StringArrayList from "./util/StringArrayList";
import TableView from "./util/TableView";
import { ADMIN, SENDER } from "../ListItems";
import Button from "@mui/material/Button";
import { api } from "../api/api";

export default function UsersView(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    props.setShowSearch(true);
  }, []);

  const tableHeader = (
    <TableRow>
      <TableCell>Id</TableCell>
      <TableCell>Name</TableCell>
      <TableCell>Email</TableCell>
      <TableCell>City</TableCell>
      <TableCell>Role</TableCell>
      <TableCell>Actions</TableCell>
    </TableRow>
  );

  const tableRows = (user, index) => (
    <TableRow key={user.id}>
      <TableCell>{user.id}</TableCell>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.city}</TableCell>
      <TableCell>
        <StringArrayList
          strings={user.roles}
          stringChange={(str) => str.substring(5, str.length)}
        />
      </TableCell>
      <TableCell>
        <div>
          {[ADMIN, SENDER].map((role) => {
            return roleButton(role, user, index);
          })}
        </div>
      </TableCell>
    </TableRow>
  );

  const roleButton = (role, user, index) => {
    const include = user.roles.includes(role);

    return (
      <Button
        key={role}
        onClick={() => {
          callRoleChange(user.id, role, !include, index);
        }}
        color="primary"
      >
        {`${include ? "Unmake" : "Make"} ${role.substring(5, role.length)}`}
      </Button>
    );
  };

  const callRoleChange = (userId, role, assign, index) => {
    (async () => {
      api.user
        .changeRole(assign, userId, role)
        .then((user) => {
          let newArr = data.slice(0, data.length);
          newArr[index] = user;
          setData(newArr);
        })
        .catch((error) => {
          props.onFetchFailed(error);
        });
    })();
  };

  return (
    <TableView
      totalUrl={"user/total"}
      fetchUrl={"user/all"}
      tableHeader={tableHeader}
      tableRows={tableRows}
      searchValue={props.searchValue}
      data={data}
      setData={setData}
      {...props}
    />
  );
}
