import React, { useState, useEffect } from "react";
import { api } from "../../api/api";
import { Box } from "@mui/material";
import transformCountryWithCities from "./utils/transformCountryWithCities";
import FilterBar from "./components/FilterBar/FilterBar";
import { Button, Panel } from "@epam/uui";
import { useUuiContext } from "@epam/uui-core";
import { ReactComponent as ActionAddOutlineIcon } from "@epam/assets/icons/action-add-outline.svg";
import { ReactComponent as NotificationInfoOutlineIcon } from "@epam/assets/icons/notification-info-outline.svg";
import CourierCreate from "../dialog/couriers/CourierDetailView";
import CourierTable from "./components/CourierTable/CourierTable";
import "./CourierView.css";
import UserGuideModal from "./components/UserGuideModal/UserGuideModal";

interface Props {
  snackBarAddMessage: (message: string, type: string) => void;
  onFetchFailed: (error: any) => void;
  user: { id: number; roles: string[]; name: string };
}

export interface DialogCourier {
  id: number;
  courierType: string;
  destination: string;
  destinationType: string;
  details: string;
  source: string;
  creator: { id?: number; name?: string };
}

const CouriersView: React.FC<Props> = (props) => {
  const [sizes, setSizes] = useState([]);
  const [countries, setCountries] = useState<{ id: string; name: string }[]>(
    []
  );
  const [cityList, setCityList] = useState([]);
  const [filter, setFilter] = useState({});

  const [updatedCourier, setUpdatedCourier] = useState({});

  const svc = useUuiContext();

  const fetchAllData = () => {
    async function fetchDataFromAPI() {
      try {
        const sizeResponse = await api.dictionary.sizeAll();
        setSizes(sizeResponse.content);

        const [countryResponse, cityResponse] = await Promise.all([
          api.dictionary.countryAll(),
          api.dictionary.locationAll(),
        ]);

        const countryData = countryResponse.content;
        const cityData = cityResponse.content;
        setCityList(cityData);

        const mergedData = transformCountryWithCities(countryData, cityData);

        setCountries([
          ...mergedData,
          {
            countryCode: "other",
            name: "Other",
            id: "other",
            cityList: [],
          },
        ]);
      } catch (error) {
        props.snackBarAddMessage(`${error}`, "error");
      }
    }
    fetchDataFromAPI();
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const submitFilters = (filters: any) => {
    setFilter(filters);
  };

  return (
    <Panel cx="container">
      <Box display={"flex"} justifyContent={"space-between"} marginBottom={2}>
        <Button
          color="primary"
          caption="Create"
          icon={ActionAddOutlineIcon}
          iconPosition="right"
          onClick={() => {
            svc.uuiModals.show((props) => (
              <CourierCreate
                countries={countries}
                sizes={sizes}
                onUpdate={setUpdatedCourier}
                cityList={cityList}
                {...props}
              />
            ));
          }}
        />
        <Button
          icon={NotificationInfoOutlineIcon}
          iconPosition="right"
          onClick={() =>
            svc.uuiModals.show((props) => <UserGuideModal {...props} />)
          }
          color="secondary"
          fill="outline"
          caption="How to use "
        />
      </Box>
      <FilterBar
        onSubmit={submitFilters}
        countryList={countries}
        sizeList={sizes}
      />
      <CourierTable
        sizeList={sizes}
        countryList={countries}
        user={props.user}
        updatedCourier={updatedCourier}
        onCourierUpdate={setUpdatedCourier}
        filter={filter}
        cityList={cityList}
      />
    </Panel>
  );
};
export default CouriersView;
