import React, { useMemo, useState, useCallback } from "react";
import {
  DataColumnProps,
  useUuiContext,
  INotification,
  useLazyDataSource,
  DataQueryFilter,
  LazyDataSourceApiRequestRange,
  PatchOrdering,
} from "@epam/uui-core";
import {
  DataTable,
  Panel,
  Text,
  LinkButton,
  SuccessNotification,
  ErrorNotification,
  DataTableRow,
} from "@epam/uui";
import CourierCreate from "../../../dialog/couriers/CourierDetailView";
import { lowerCaseAllWordsExceptFirstLetters } from "../../../../api/helper";
import "./CourierTable.css";
import { isEmpty, isUndefined } from "lodash";
import clsx from "clsx";
import { ADMIN } from "../../../../ListItems";
import ConfirmMatchDialog from "../ConfirmMatchDialog/ConfirmMatchDialog";
import CourierDeactivateDialog from "../../../dialog/couriers/components/CourierDeactivateDialog/CourierDeactivateDialog";

interface ICourierTable {
  sizeList: any;
  countryList: any;
  user: any;
  updatedCourier?: any;
  filter: any;
  onCourierUpdate: (courier: any) => void;
  cityList: any;
}

let patch = new Map();

export default function CourierTable({
  sizeList,
  cityList,
  countryList,
  user,
  updatedCourier,
  onCourierUpdate,
  filter,
}: ICourierTable) {
  const svc = useUuiContext();
  const [value, onValueChange] = useState({});
  const isAdmin = user.roles.includes(ADMIN);
  const courierToUpdate = isEmpty(updatedCourier) ? new Map() : updatedCourier;
  patch = new Map([...patch, ...courierToUpdate]);

  const dataSource = useLazyDataSource<any, string, DataQueryFilter<any>>(
    {
      api: async (request) => {
        const { sorting } = request;
        const { count, from } = request.range as LazyDataSourceApiRequestRange;
        const response = {
          items: await svc.api.table
            .getContent("courier/all", from, count, "POST", {
              sort: {
                sort: sorting?.[0].field,
                sortDirection: sorting?.[0].direction?.toUpperCase(),
              },
              filter,
            })
            .then((r: any) => r.content)
            .catch((e: any) => console.log(e)),
        };

        return response;
      },
      getId: (p) => p.id,
      patch: isEmpty(filter) ? patch : new Map(),
      isDeleted: (item) => item.isDeleted ?? false,
      getNewItemPosition: () => PatchOrdering.BOTTOM,
      filter,
    },
    []
  );

  const renderActions = (courier: any) => {
    const { id, active, courierType, creator } = courier;
    if (!active) return null;

    if (user.id === creator.id) {
      return (
        <LinkButton
          caption="Complete"
          onClick={() =>
            svc.uuiModals.show((props) => (
              <CourierDeactivateDialog
                {...props}
                courier={courier}
                onDeactivate={() => props.success(true)}
                onUpdate={onCourierUpdate}
              />
            ))
          }
        />
      );
    }

    if (courierType === "SENDER") {
      return (
        <LinkButton
          caption="I can help"
          onClick={() =>
            svc.uuiModals.show((props) => (
              <ConfirmMatchDialog
                {...props}
                onCourierSubmit={() => onCourierSubmit(id)}
              />
            ))
          }
        />
      );
    }

    if (courierType === "COURIER") {
      return (
        <LinkButton
          caption="Transport my parcel"
          onClick={() =>
            svc.uuiModals.show((props) => (
              <ConfirmMatchDialog
                {...props}
                isCourierRequest
                onCourierSubmit={() => onCourierSubmit(id)}
              />
            ))
          }
        />
      );
    }

    return null;
  };

  const couriersColumns: DataColumnProps<any>[] = useMemo(
    () => [
      {
        key: "courierType",
        caption: "Type",
        render: (courier) => (
          <Text color="secondary">
            {lowerCaseAllWordsExceptFirstLetters(courier.courierType)}
          </Text>
        ),
        isSortable: true,
        width: 100,
        isAlwaysVisible: true,
      },
      {
        key: "creator",
        caption: "Creator",
        render: ({ creator }) => <Text color="secondary">{creator.name}</Text>,
        isSortable: true,
        width: 200,
      },
      {
        key: "sourceCountry",
        caption: "From",
        render: ({ sourceCity, sourceCountry }) => (
          <Text>
            {sourceCity}, {sourceCountry}
          </Text>
        ),
        width: 180,
        isSortable: true,
      },
      {
        key: "destinationCountry",
        caption: "To",
        render: ({ destinationCity, destinationCountry, sourceCountry }) => (
          <Text>
            {destinationCity}, {destinationCountry || sourceCountry}
          </Text>
        ),
        width: 180,
        isSortable: true,
      },
      {
        key: "size",
        caption: "Size",
        render: ({ size }) => <Text>{size.name}</Text>,
        width: 120,
        isSortable: true,
      },
      {
        key: "details",
        caption: "Details",
        render: ({ details }) => <Text>{details}</Text>,
        width: 200,
        isSortable: true,
      },
      {
        key: "expiresAt",
        caption: "Until",
        render: ({ expiresAt }) => <Text>{expiresAt}</Text>,
        width: 120,
        isSortable: true,
      },
      {
        key: "actions",
        render: (courier) => renderActions(courier),
        width: 180,
        isAlwaysVisible: true,
        fix: "right",
      },
    ],
    []
  );

  const view = dataSource.useView(value, onValueChange, {
    getRowOptions: () => {
      return {
        onClick: async (rowProps) => {
          if (rowProps.value.active || isAdmin) {
            const courier = await svc.api.courier.get(rowProps.id);
            const isViewMode = courier.creator.id !== user.id && !isAdmin;
            svc.uuiModals.show((props) => (
              <CourierCreate
                countries={countryList}
                sizes={sizeList}
                courier={courier}
                isViewMode={isViewMode}
                onUpdate={onCourierUpdate}
                cityList={cityList}
                {...props}
              />
            ));
          }
        },
      };
    },
  });

  const onCourierSubmit = (courierId: number) => {
    (async () => {
      svc.api.courier
        .match(courierId)
        .then(() => {
          svc.uuiNotifications
            .show((props: INotification) => (
              <SuccessNotification {...props}>
                <Text size="36" fontSize="14">
                  Match email was sent
                </Text>
              </SuccessNotification>
            ))
            .catch(() => null);
        })
        .catch((error: any) => {
          svc.uuiNotifications.show((props: INotification) => (
            <ErrorNotification {...props}>
              <Text size="36" fontSize="14">
                {error}
              </Text>
            </ErrorNotification>
          ));
        });
    })();
  };

  const renderRow = useCallback((props: any) => {
    return (
      <DataTableRow {...props} cx={clsx(!props.value?.active && "disabled")} />
    );
  }, []);

  return (
    <Panel background="surface-main" shadow cx="container">
      <DataTable
        {...view.getListProps()}
        getRows={view.getVisibleRows}
        value={value}
        onValueChange={onValueChange}
        columns={couriersColumns}
        headerTextCase="normal"
        renderRow={renderRow}
      />
    </Panel>
  );
}
