import React, { useState } from "react";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider } from "notistack";

import "dayjs/locale/en-gb";

import MainScreen from "./app/MainScreen";
import Box from "@mui/material/Box";

const theme = createTheme();

export default function App() {
  return (
    <Box id="box-root">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={30}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <MainScreen />
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Box>
  );
}
