import React, { useCallback, useEffect, useReducer, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { emailRegexp, enhancedReducer } from "../util/Everything";
import CircularProgress from "@mui/material/CircularProgress";
import DisplayMessage from "../util/DisplayMessage";
import { api } from "../../api/api";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  emailsTitle: {
    marginTop: theme.spacing(3),
  },
  emailButton: {
    marginTop: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const initialState = {
  id: "",
  name: "",
  alternativeName: "",
  city: "Malaga",
  emails: [""],
};

export default function ReceiverDetailView(props) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const snackBarAddMessage = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [receiver, updateReceiver] = useReducer(enhancedReducer, initialState);
  const [noData, setNoData] = useState(false);
  const [save, setSave] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [loading, setLoading] = useState(props.receiverId != null);

  const updateForm = useCallback(({ target: { value, name, type } }) => {
    const updatePath = name.split(".");
    // if the input is a checkbox then use callback function to update
    // the toggle state based on previous state
    if (type === "checkbox") {
      updateReceiver((prevState) => ({
        [name]: !prevState[name],
      }));

      return;
    }

    // if we have to update the root level nodes in the form
    if (updatePath.length === 1) {
      const [key] = updatePath;

      updateReceiver({
        [key]: value,
      });
    }

    // if we have to update nested nodes in the form object
    // use _path and _value to update them.
    if (updatePath.length === 2) {
      updateReceiver({
        _path: updatePath,
        _value: value,
      });
    }
    if (value !== "" && (updatePath[0] === "id" || updatePath[0] === "name")) {
      onIdOrNameValueChange(value);
    }
  }, []);

  const onIdOrNameValueChange = (value) => {
    let regex = /^(.+) <(.+)>$/;
    if (!regex.test(value)) {
      return;
    }
    let parse = value.match(regex);

    updateReceiver({
      name: parse[1],
      id: parse[2],
      epamEmail: parse[2],
    });
  };

  useEffect(() => {
    if (!loading) {
      return undefined;
    }
    (async () => {
      await api.receiver
        .get(props.receiverId)
        .then((receiver) => {
          setNoData(false);
          updateReceiver(receiver);
          setLoading(false);
        })
        .catch((error) => {
          setNoData(true);
          setLoading(false);
          if (props.onFetchFailed) {
            props.onFetchFailed(error);
          }
        });
    })();
  }, [loading]);

  useEffect(() => {
    if (!save) {
      return;
    }
    api.receiver
      .save(props.receiverId, receiver)
      .then((receiver) => {
        updateReceiver(receiver);
        snackBarAddMessage(
          "User information was successfully updated",
          "success"
        );
      })
      .catch((error) => {
        props.onFetchFailed(error);
        snackBarAddMessage(
            `${error}`,
            "error"
          );
      });

    setSave(false);
  }, [save]);

  // ======= Emails =============
  const handleOnEmailAdd = () => {
    let newEmails = receiver.emails.slice(0, receiver.emails.length);
    newEmails.push("");
    updateReceiver({
      emails: newEmails,
    });
  };

  const handleOnEmailRemove = (index) => {
    let newEmails = receiver.emails.slice(0, receiver.emails.length);
    newEmails.splice(index, 1);
    updateReceiver({
      emails: newEmails,
    });
  };

  const handleEmailUpdate = (index, value) => {
    let newEmails = receiver.emails.slice(0, receiver.emails.length);
    newEmails[index] = value;
    updateReceiver({
      emails: newEmails,
    });
  };

  const validateEmail = (email) => {
    if (email === "") {
      return true;
    }
    return emailRegexp.test(String(email).toLowerCase());
  };

  // ======= /Emails =============

  const validateFields = (receiver) => {
    return (
      receiver.emails.filter((email) => !validateEmail(email)).length === 0 &&
      receiver.emails.length >= 1 &&
      receiver.id !== "" &&
      receiver.name !== "" &&
      receiver.city !== "" &&
      receiver.alternativeName !== receiver.name
    );
  };

  const handleOnSave = () => {
    if (!validateFields(receiver)) {
      console.log("Validation failed");
      setValidationError("Validate and fill all required fields");
      return;
    }
    setValidationError(null);

    let emailsToSave = receiver.emails.filter((email) => email !== "");
    if (emailsToSave.length === 0) {
      emailsToSave.push("");
    }
    updateReceiver({
      emails: emailsToSave,
    });
    setSave(true);

    if (props.handleClose) {
      props.handleClose();
    }
  };

  const handleOnReset = () => {
    setLoading(true);
  };

  const getEmailButton = (index, size) => {
    return (
      <Box sx={{ display: "flex" }}>
        {index !== 0 && (
          <IconButton
            color="primary"
            className={classes.emailButton}
            onClick={() => handleOnEmailRemove(index)}
            size="large"
          >
            <RemoveIcon />
          </IconButton>
        )}
        {index === size - 1 && (
          <IconButton
            color="primary"
            className={classes.emailButton}
            onClick={handleOnEmailAdd}
            size="large"
          >
            <AddIcon />
          </IconButton>
        )}
      </Box>
    );
  };

  const validationErrorRender = () => {
    if (validationError) {
      return <DisplayMessage message={validationError} />;
    }
  };

  return (
    <div>
      {loading ? (
        <div style={{ display: "flex" }}>
          <CircularProgress style={{ margin: "0 auto" }} />
        </div>
      ) : noData ? (
        <div>
          <Typography variant="h5" gutterBottom>
            No data for current user.
          </Typography>
        </div>
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                required
                id="id"
                name="id"
                label="Id"
                value={props.receiverId}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/*Total notifications here*/}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                required
                id="name"
                name="name"
                label="Name"
                value={receiver.name}
                onChange={updateForm}
                disabled={props.readOnly}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip title="If you expect to receive packages on some other name except yours(for example you wife, child, etc.) please specify it here">
                <TextField
                  variant="standard"
                  id="alternativeName"
                  name="alternativeName"
                  label="Alternative name"
                  value={receiver.alternativeName}
                  onChange={updateForm}
                  error={receiver.alternativeName === receiver.name}
                  helperText={
                    receiver.alternativeName === receiver.name &&
                    "Fill this field ONLY in case you expect packages for" +
                      " some other name for example your partner's," +
                      " child's, etc."
                  }
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                required
                id="city"
                name="city"
                label="City"
                value={receiver.city}
                onChange={updateForm}
                disabled={props.readOnly}
                fullWidth
              />
            </Grid>
          </Grid>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.emailsTitle}
          >
            Emails:
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                required
                id="epamEmail"
                name="epamEmail"
                label="EPAM email"
                value={receiver.epamEmail}
                onChange={updateForm}
                disabled={props.readOnly}
                fullWidth
              />
            </Grid>
          </Grid>
          {receiver.emails.map((email, index) => (
            <Grid container key={index} spacing={3}>
              <Grid item xs={12} sm={10}>
                <TextField
                  variant="standard"
                  error={!validateEmail(receiver.emails[index])}
                  id={`email-${index}`}
                  name="email"
                  label="Email"
                  value={receiver.emails[index]}
                  onChange={(event) => {
                    handleEmailUpdate(index, event.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item key={index} xs={12} sm={2}>
                {getEmailButton(index, receiver.emails.length)}
              </Grid>
            </Grid>
          ))}
          {validationErrorRender()}
          <div className={classes.buttons}>
            <Button onClick={handleOnReset} className={classes.button}>
              Reset
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleOnSave}
              className={classes.button}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
