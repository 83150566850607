import React, {useEffect, useState} from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableView from "./util/TableView";
import ReceiverDetailDialog from "./dialog/ReceiverDetailDialog";
import Fab from "@mui/material/Fab";
import AddIcon from '@mui/icons-material/Add';
import makeStyles from "@mui/styles/makeStyles";
import StringArrayList from "./util/StringArrayList";

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    rowCursor:{
        cursor: "pointer"
    }
}));

export default function ReceiverView(props) {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogReceiver, setDialogReceiver] = useState({
        id: "",
        name: "",
        alternativeName: "",
        city: "",
        emails: [
            "",
        ]
    });

    useEffect(() => {
        props.setShowSearch(true);
    }, []);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const tableHeader =
        <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>EPAM Email</TableCell>
            <TableCell>Emails</TableCell>
            <TableCell>City</TableCell>
            <TableCell>Active</TableCell>
        </TableRow>;

    const tableRows = (row) =>
        <TableRow
            className={classes.rowCursor}
            key={row.id}
            onClick={() => {
                setDialogReceiver(row);
                setDialogOpen(true);
            }}
        >
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.epamEmail}</TableCell>
            <TableCell><StringArrayList strings={row.emails}/></TableCell>
            <TableCell>{row.city}</TableCell>
            <TableCell>{row.active? 'True': 'False'}</TableCell>
        </TableRow>;


    return (
        <>

            <ReceiverDetailDialog
                component={'span'}
                open={dialogOpen}
                handleClose={handleDialogClose}
                // handleApprove={handleDialogApprove}
                // handleOnItemClear={handleOnItemClear}
                receiver={dialogReceiver}
                {...props}
            />
            <TableView
                totalUrl={"receiver/total"}
                fetchUrl={"receiver/all"}
                tableHeader={tableHeader}
                tableRows={tableRows}
                searchValue={props.searchValue}
                data={data}
                setData={setData}
                {...props}
            />
            <Fab
                color="secondary"
                className={classes.fab}
                aria-label="add"
                onClick={() => {
                    setDialogReceiver(null);
                    setDialogOpen(true);
                }}
            >
                <AddIcon/>
            </Fab>
        </>
    );
}
